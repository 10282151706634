import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./app/store";

import axios from "axios";
import DoctorDashBoard from "./DoctorDashBoard";
import LoginDoctor from "./components/LoginDoctor";
import PrivateRoutesLab from "./PrivateRouteLab";
import Profile from "./Profile";
function App() {
  axios.defaults.baseURL = "https://aurora-team.com/lab/api/";

  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("doctor_token");

  return (
    <div className="bg-[#F2F4F7] h-screen w-full  ">
      <Router>
        <Provider store={store}>
          <Routes>
            <Route path="/Login" element={<LoginDoctor />} />
            <Route element={<PrivateRoutesLab />}>
              <Route path="/" element={<DoctorDashBoard />} />
              <Route path="/Profile" element={<Profile />} />
            </Route>
          </Routes>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
