import React, { useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { VscListFlat } from "react-icons/vsc";

import axios from "axios";
import Swal from "sweetalert";
import HeaderDoctorDH from "./components/HeaderDoctorDH";
function Profile() {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    newPassword: "",
    ConfnewPassword: "",
  });

  const [newPassword, setNewPassword] = useState();
  const [confnewPassword, setConfnewPassword] = useState();
  const [ready, setReady] = useState(true);
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState("");
  const [error, setError] = useState("");

  const GetProfile = async () => {
    await axios
      .get(`doctor-scope/doctor-profile`)
      .then((response) => {
        setFormData({
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          address: response.data.address,
          phone: response.data.phone,
          gender: response.data.gender,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const Change = async () => {
    setReady(false);
    await axios
      .put(
        `doctor-scope/update-profile?password=${newPassword}&password_confirmation=${confnewPassword}`
      )
      .then((response) => {
        setNewPassword("");
        setConfnewPassword("");
        setReady(true);

        Swal({
          title: "Good job!",
          type: "success",
          icon: "success",
        });
        setError("");

        setErrorNewPassword("");
        setErrorConfirmNewPassword("");
      })
      .catch((err) => {
        if (error.response.status === 500) {
          Swal({
            title: "Opps, Error",
            type: "error",
            icon: "error",
            text: `The system is under maintenance, please try again later, thank you`,
          });
        } else {
          if (err.response.data.errors.new_password) {
            setErrorNewPassword(err.response.data.errors.new_password[0]);
          } else {
            setErrorNewPassword("");
          }
          if (err.response.data.errors.new_password_confirmation) {
            setErrorConfirmNewPassword(
              err.response.data.errors.new_password_confirmation[0]
            );
          } else {
            setErrorConfirmNewPassword("");
          }
        }
      });
  };

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleChangeConfNewPassword = (e) => {
    setConfnewPassword(e.target.value);
  };
  return (
    <div className="w-full h-full pr-5 p-5">
      <div className="w-full flex ">
        <HeaderDoctorDH />
      </div>
      <div className="flex ">
        {/* <SideBarSystemDB page="Profile" /> */}
        <div className="w-full h-full lg:ml-8 mt-10 pb-48">
          {/* Pangration */}
          <div className={`  mt-10 mb-10`}>
            <div className="grid grid-cols-2 gap-5 w-full justify-between ">
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    disabled
                    id="email"
                    placeholder="Email"
                    value={`${formData.email}`}
                    type="email"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                  <p
                    id="email"
                    className=" text-sm font-Poppins-Medium absolute top-[-0.8rem]  left-[0.2rem]  px-1 text-[#B7C835] font-medium"
                  >
                    Email
                  </p>
                </div>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    disabled
                    id="Name"
                    placeholder="Name"
                    value={`${formData.name}`}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                  <p
                    id="Name"
                    className=" text-sm font-Poppins-Medium absolute top-[-0.8rem]  left-[0.2rem]  px-1 text-[#B7C835] font-medium"
                  >
                    Name
                  </p>
                </div>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    disabled
                    id="phone"
                    placeholder="Phone"
                    value={`${formData.phone}`}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                  <p
                    id="phone"
                    className=" text-sm font-Poppins-Medium absolute top-[-0.8rem]  left-[0.2rem]  px-1 text-[#B7C835] font-medium"
                  >
                    phone
                  </p>
                </div>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    disabled
                    id="gender"
                    placeholder="Gender"
                    value={`${formData.gender}`}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                  <p
                    id="gender"
                    className=" text-sm font-Poppins-Medium absolute top-[-0.8rem]  left-[0.2rem]  px-1 text-[#B7C835] font-medium"
                  >
                    gender
                  </p>
                </div>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    disabled
                    id="address"
                    placeholder="Address"
                    value={`${formData.address}`}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                  <p
                    id="Address"
                    className=" text-sm font-Poppins-Medium absolute top-[-0.8rem]  left-[0.2rem]  px-1 text-[#B7C835] font-medium"
                  >
                    Address
                  </p>
                </div>
              </div>
              <div className="w-full col-start-1 col-end-3">
                <p className="text-sm ">Reset Password</p>
                <hr className="  bg-gray-200 border-[1px] my-2 w-full rounded-lg" />
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="Newpassword"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handleChangeNewPassword}
                    type="password"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                  <p
                    id="password"
                    className=" text-sm font-Poppins-Medium absolute top-[-0.8rem]  left-[0.2rem]  px-1 text-[#B7C835] font-medium"
                  >
                    password
                  </p>
                </div>

                <span className="text-sm text-red-600">{errorNewPassword}</span>
              </div>

              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="ConfirmPassword"
                    placeholder="Confirm password"
                    value={confnewPassword}
                    onChange={handleChangeConfNewPassword}
                    type="password"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                  <p
                    id="ConfirmPassword"
                    className=" text-sm font-Poppins-Medium absolute top-[-0.8rem]  left-[0.2rem]  px-1 text-[#B7C835] font-medium"
                  >
                    ConfirmPassword
                  </p>
                </div>

                <span className="text-sm text-red-600">
                  {errorConfirmNewPassword}
                </span>
              </div>

              <button
                onClick={() => Change()}
                disabled={!ready}
                className={`${
                  ready ? "bg-[#0D2135]" : "bg-gray-500"
                } col-start-1 col-end-3 ml-auto  w-[30%] lg:w-[20%]  flex items-center justify-center px-5 lg:px-10  py-2 rounded-xl`}
              >
                <p className="text-sm flex items-center justify-center text-center text-white font-Poppins-Medium">
                  Change
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
