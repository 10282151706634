import React, { useEffect, useState } from "react";

// import "react-datepicker/dist/react-datepicker.css";
import HeaderDoctorDH from "./components/HeaderDoctorDH";
// import DoctorDashBoardPangration from "./components/DoctorDashBoardPangration";
import { AiOutlineEye } from "react-icons/ai";
import ShowTest from "./components/ShowTest";
import axios from "axios";
import Pagination from "react-js-pagination";

const labs = [
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
  {
    id: 1,
    patientName: "ahmed",
    date: "1/1/2023",
    LabName: "halabi",
  },
];

function DoctorDashBoard() {
  const [OpenShowTest, setOpenShowTest] = useState(false);
  const [getDoctor, setGetDoctor] = useState();

  const [Id, setId] = useState();
  const getDoctors = async (page) => {
    await axios
      .get(`doctor-scope/my-analysies?page=${page}`)
      .then((response) => {
        console.log(response.data);
        // dispatch(addAllDoctor(response.data));
        // setDataForFilter(response.data.data);
        // SetDataBeforeFilter(response.data);
        setGetDoctor(response.data);
      });
  };
  useEffect(() => {
    getDoctors(1);
  }, []);

  const Show = (id) => {
    setId(id);
    setOpenShowTest(true);
  };

  const handlePageChange = (pageNumber) => {
    getDoctors(pageNumber);
  };
  return (
    <div className="w-full h-full pr-5 p-5">
      <HeaderDoctorDH />
      <div className="flex ">
        <div className="w-full h-full lg:ml-8 mt-10">
          {/* <div className="flex w-full justify-between">
            <div className="flex w-full  space-x-2">
              <div className="w-fit pr-2 bg-white rounded-lg flex items-center mr-5">
                <select className=" w-fit  rounded-lg font-Poppins-Medium  text-base outline-none px-4 py-2 cursor-pointer">
                  <option value="" selected disabled hidden>
                    Sort by
                  </option>
                  <option value="A-Z">A-Z</option>
                  <option value="Z-A">Z-A</option>
                </select>
              </div>
            </div>
          </div> */}

          {/* Pangration */}
          <div className="w-full">
            <div className={`overflow-x-scroll`}>
              <ShowTest
                getDoctor={getDoctor}
                setGetDoctor={setGetDoctor}
                id={Id}
                open={OpenShowTest}
                setOpen={setOpenShowTest}
              />
              <table className="w-full h-full mt-8 bg-white rounded-t-2xl ">
                <tr className="border-b-[1px] w-full">
                  {/* <td className="w-[5%] pr-2 lg:pr-0">
                    <input
                      type="checkbox"
                      className="text-[#E4E7EC] border-[#E4E7EC] border-[1px] ml-5 "
                    />
                  </td> */}
                  <td className="text-sm pr-24 lg:pr-0 text-[#98A2B3] font-Poppins-Regular py-2 pl-3">
                    Patient Name
                  </td>
                  <td className="text-sm pr-20 lg:pr-0 text-[#98A2B3] font-Poppins-Regular py-2 w-[25%] ">
                    Date of visit
                  </td>
                  <td className="text-sm pr-20 lg:pr-0 text-[#98A2B3] font-Poppins-Regular py-2 ">
                    Phone number
                  </td>
                  <td className="text-sm pr-20 lg:pr-0 text-[#98A2B3] font-Poppins-Regular py-2 ">
                    Gender
                  </td>
                  <td className="text-sm pr-20 lg:pr-0 text-[#98A2B3] font-Poppins-Regular py-2 ">
                    Age
                  </td>
                  <td className="text-sm pr-20 lg:pr-0 text-[#98A2B3] font-Poppins-Regular py-2 w-[9%]">
                    Action
                  </td>
                </tr>
                {getDoctor &&
                  getDoctor.data.map((item) => (
                    <tr className="border-b-[1px] ">
                      {/* <td className="w-fit">
                        <input
                          type="checkbox"
                          className="text-[#E4E7EC] border-[#E4E7EC] border-[1px] ml-5 w-fit"
                        />
                      </td> */}
                      <td className="font-Poppins-Regular text-black text-base font-semibold py-6 flex flex-col ml-2">
                        <p>{item.name}</p>
                        <p className="text-xs font-medium text-[#908F8F]">
                          {item.email}
                        </p>
                      </td>
                      <td className="font-Poppins-Regular text-black text-base font-semibold py-6 ">
                        {item.date_of_visit}
                      </td>
                      <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-2">
                        {item.phone_number}
                      </td>
                      <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-2">
                        {item.gender}
                      </td>
                      <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-2">
                        {item.age}
                      </td>
                      <td>
                        <div className="flex space-x-2 py-4">
                          <AiOutlineEye
                            className="text-2xl text-black cursor-pointer"
                            onClick={() => Show(item.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
            <div className="flex justify-center ">
              <Pagination
                activePage={getDoctor && getDoctor.meta.current_page}
                itemsCountPerPage={getDoctor && getDoctor.meta.per_page}
                totalItemsCount={getDoctor && getDoctor.meta.total}
                pageRangeDisplayed={5}
                innerClass="flex justify-center space-x-5 mb-2 border-gray-400 w-full  bottom-0   items-center mt-10"
                itemClass={` bg-white  py-1 px-3 rounded-lg`}
                activeClass="bg-[#B7C835] text-[#FFFFFF]"
                onChange={handlePageChange.bind(this)}
              />
            </div>
          </div>
          {/* <DoctorDashBoardPangration itemsPerPage={8} Data={labs} /> */}
        </div>
      </div>
    </div>
  );
}

export default DoctorDashBoard;
